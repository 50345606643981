@import './inc/style.scss';
@import './inc/static-text.scss';
@import './inc/blog-item.scss';
@import './inc/pager-area.scss';

.article-page-content {
    h1 {
        margin-bottom:10px;
    }

    .date-row {
        color:$dark-gray;
        line-height:18px;

        img {
            margin:0px;
        }

        .separator {
            width:4px;
            height:4px;
            border-radius: 50%;
            margin:auto 10px;
            background:$dark-gray;
        }
    }
}

.blog-page-content {
    h1 {
        margin-bottom:40px;
    }

    .content-width {
        max-width:1000px;
    }

    .articles-list {
        gap:30px 30px;
        grid-template-columns: repeat(3, calc((100% - 60px) / 3));
    }

    .highlighted-article {
        display:flex;
        margin-bottom:40px;

        .image-outer {
            margin-bottom:0px;
            width:53.667%;
            padding-top:34.444%;
        }

        .text-content {
            width:46.333%;
            position:relative;
            border:1px solid $beige;
            border-left:0;
            padding:30px;

            .details-row {
                position:absolute;
                right:30px;
                bottom:25px;
            }
        }

        &.without-image {
            .text-content {
                width:100%;
                border:1px solid $beige;
            }
        }
    }
}


@include breakpoint(900px) {
    .blog-page-content {
        .articles-list {
            grid-template-columns: repeat(2, calc((100% - 30px) / 2));
        }

        .pager-section {
            .inner-content {
                justify-content:center;
            }
        }
    }
}

@include below-standing-tablet {
    .blog-page-content {
        .highlighted-article {
            display:block;

            .image-outer {
                width:100%;
                padding-top:64.3%;
            }

            .text-content {
                width:100%;
                border:1px solid $beige;
                border-top:0;

                .details-row {
                    position:relative;
                    bottom:auto;
                    right:auto;
                }
            }
        }
    }
}

@include mobile {
    .blog-page-content {
        h1 {
            margin-bottom:20px;
        }

        .highlighted-article {
            margin-bottom:30px;

            .text-content {
                padding:15px;
            }
        }

        .articles-list {
            display:block;

            .blog-item {
                width:100%;
                margin-bottom:25px;
                padding-bottom:25px;
                border-bottom: 1px solid $beige;

                &:last-of-type {
                    margin-bottom:0px;
                    
                }
            }
        }
    }
}