.pager-section {
    margin-top:40px;

    .pager-item {
        width:28px;
        height:28px;
        color:$primary-color;
        background:$white;
        text-decoration: none;
        transition:$bg-color-transition, $color-transition, $border-color-transition;
        border:1px solid transparent;
        display:flex;
        justify-content:center;
        align-items:center;
        font-size:16px;
        line-height:16px;
        margin:0px 5px;
        font-weight:$normal-fweight;

        &.active {
            background:$light-blue;
            font-weight:$bold-fweight;
        }
    }

    .pager-button {
        font-size:0px;
        line-height:0px;
        text-decoration:none;
        width:16px;
        height:19px;
        padding:5px;
    }
}

@include hoverStatements {
    .pager-section {
        .pager-item {
            &:not(.active) {
                &:hover {
                    color:$blue;
                    border-color:$blue;
                }
            }
        }

        .pager-button {
            &:hover {
                color:$primary-color;
            }
        }
    }
}

@include mobile {
    .pager-section {
        .inner-content {
            justify-content:center;
        }
    }
}