.blog-item {
    .image-outer {
        display:block;
        margin-bottom:15px;
        position:relative;
        height:0px;
        padding-top:64.3%;

        img {
            position:absolute;
            width:100%;
            height:100%;
            object-fit: cover;
            object-position: center center;
            top:0px;
            left:0px;
            right:0px;
            bottom:0px;
        }
    }

    .top-row {
        margin-bottom:14px;
        color:$dark-gray;

        .separator {
            width:4px;
            height:4px;
            min-width:4px;
            background:$dark-gray;
            border-radius:50%;
            margin:0px 10px 0px 10px;
        }

        .logo {
            width: 21px;
            min-width:21px;
        }
    }

    .title {
        font-size:20px;
        line-height:24px;
        text-decoration: none;
    }

    .text {
        margin-top:10px;
        color:$dark-gray;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .details-row {
        margin-top:15px;
        font-size:14px;
        line-height:16px;
    }
}

@include hoverStatements {
    .blog-item {
        .title {
            &:hover {
                text-decoration:none;
                color:$blue;
            }
        }
    }
}

@include below-standing-tablet {
    .blog-item {
        .top-row {
            margin-bottom:7px;

            .logo, .separator {
                display:none;
            }
        }
    }
}

@include mobile {
    .blog-item {
        .top-row {
            margin-bottom:4px;
            font-size:14px;
            line-height:16px;
        }

        .title {
            font-size:18px;
            line-height:22px;
        }

        .text {
            -webkit-line-clamp: 2;
        }

        .details-row {
            margin-top:10px;
        }
    }
}