.static-text-content {
    width:100%;
    margin:auto;
    max-width:850px;
    line-height:24px;

    > * {
        width:calc(100% - 110px);
        margin-left:auto;
        margin-right:auto;

        &:first-child {
            margin-top:0px!important;
        }

        &:last-child {
            margin-bottom:0px!important;
        }
    }

    > img {
        width:auto;
        margin:30px auto;
    }

    img, picture {
        margin-left:auto;
        margin-right:auto;
    }

    blockquote {
        margin:20px auto 30px;
    }

    p {
        margin-bottom:24px;

        &:last-of-type, &:empty {
            margin-bottom:0px;
        }
    }

    hr {
        margin:30px auto;
        height:1px;
        border:0;
        display:block;
        background:$beige;
    }

    ul, ol {
        margin:15px auto 30px;
        padding-left:30px;

        li {
            position:relative;
            padding-left:16px;
            margin-bottom:20px;

            &:before {
                content:'';
                top:0px;
                left:0px;
                position:absolute;
                display:block;
            }

            &:last-of-type {
                margin-bottom:0px;
            }
        }

        & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
            margin-top:40px;
        }
    }

    ul {
        li {
            &:before {
                width:6px;
                height:6px;
                background:$blue;
                border-radius: 50%;
                top:8px;
            }
        }
    }

    ol {
        counter-reset: pmpCounter;

        li {
            counter-increment: pmpCounter;
            padding-left:20px;

            &:before {
                content:counter(pmpCounter)".";
                font-weight:$bold-fweight;
                color:$blue;
            }
        }
    }

    h1, h2, h3, h4, h5, h6 {
        & + ul, & + ol, & + blockquote {
            margin-top:20px;
        }
    }

    h1 {
        margin:30px auto;
    }

    h2, h3 {
        margin:30px auto 20px;
    }

    h4, h5, h6 {
        margin:20px auto 15px;
    }

    .accordion-list {
        border-top:1px solid $beige;
        margin:30px auto 40px;
            

        .accordion-content {
            display: none;
            padding:10px 28px 50px 10px;
        }

        .accordion-title {
            padding:20px 10px;
            display:flex;
            justify-content:space-between;
            align-items:center;

            .icon-outer {
                width:18px;
                height:18px;
                border-radius:50%;
                background:$blue;
                font-size:0px;
                line-height:0px;
                transition:$color-transition, $bg-color-transition;
                position:relative;
                display: block;
                cursor: pointer;

                &:before, &:after {
                    content:'';
                    background:$white;
                    transition:$bg-color-transition, $opacity-transition;
                    position:absolute;
                    top:0px;
                    left:0px;
                    right:0px;
                    bottom:0px;
                    margin:auto;
                    border-radius:2px;
                }

                &:before {
                    width: 8px;
                    height: 2px;
                    z-index:2;
                }

                &:after {
                    width: 2px;
                    height: 8px;
                    z-index:1;
                }
            }

            .title {
                width:calc(100% - 28px);
                > * {
                    margin:0px;
                }
            }   
        }

        .accordion-item {
            border-bottom:1px solid $beige;

            &.active {
                .accordion-title {
                    .icon-outer {
                        background:$light-blue;

                        &:before, &:after {
                            background:$blue;
                        }

                        &:after {
                            opacity:0;
                        }
                    }
                }
            }
        }
    }

    .two-col-row {
        display:flex;
        justify-content:space-between;

        .col {
            flex: 0 0 calc(50% - 10px);
        }
    }

    hr + .accordion-list {
        border-top:0;
    }

    .form-content {
        margin:30px auto;
    }

    .download-link {
        display:inline-flex;
        align-items: center;

        .icon {
            margin-right:6px;
        }
    }
}

.back-link-row {
    margin-top:40px;
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap:wrap;

    > * {
        margin-right:30px;

        &:last-child {
            margin-right:0px;
        }
    }

    .arrow-link {
        display:inline-flex;
        align-items:center;
        text-decoration:none;

        .icon {
            margin-right:4px;
        }

        .text {
            font-size:18px;
            line-height:18px;
        }
    }
}

@include hoverStatements {
    .static-text-content {
        .accordion-list {
            .accordion-title {
                .icon-outer {
                    &:hover {
                        background:$light-blue;

                        &:before, &:after {
                            background:$blue;
                        }
                    }
                }
            }
            
            .accordion-item.active {
                .accordion-title {
                    .icon-outer {
                        &:hover {
                            background:$blue;

                            &:before, &:after {
                                background:$white;
                            }
                        }
                    }
                }
            }
        }
    }

    .back-link-row {
        .arrow-link {
            &:hover {
                color:$primary-color;
            }
        }
    }
}

@include breakpoint(767px) {
    .static-text-content {
        > * {
            width:calc(100% - #{map-get($sidePaddings, 'tablet') * 2});
        }
    }
}

@include mobile {
    .static-text-content {
        line-height:20px;

        > * {
            width:calc(100% - #{map-get($sidePaddings, 'mobile') * 2});
        }

        .two-col-row {
            display:block;
    
            .col {
                width:100%;
                margin-bottom:30px;

                &:last-of-type {
                    margin-bottom:0px;
                }
            }
        }

        p {
            margin-bottom:18px;
        }

        ul, ol {
            padding-left:12px;

            li {
                margin-bottom:15px;
            }

            & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
                margin-top:30px;
            }
        }

        ul {
            li {
                &:before {
                    top:6px;
                }
            }
        }

        h1, h2, h3, h4, h5, h6 {
            & + ul, & + ol, & + blockquote {
                margin-top:15px;
            }
        }

        h1 {
            margin:20px auto;
        }
    
        h2, h3 {
            margin:20px auto 15px;
        }
    
        h4, h5, h6 {
            margin:15px auto 10px;
        }
        
        .accordion-list {
            .accordion-title {
                padding:20px 5px;
            }

            .accordion-content {
                padding:5px 5px 30px;
            }
        }

        .form-content {
            margin-top:20px;
        }
    }

    .back-link-row {
        flex-direction:column;

        > * {
            margin:0px 0px 25px 0px;

            &:last-child {
                margin:0px;
            }
        }

        .arrow-link {
            .text {
                font-size:16px;
                line-height:16px;
            }
        }
    }
}

